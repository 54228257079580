import React from "react"
import { Container, Row, Col } from "reactstrap"
import Fade from "react-reveal/Fade"
import ProposalSVG from "./svgs/proposal"

const Proposal = () => {
  return (
    <section className="section our-proposal">
      <div className="shapes-container">
        {/* <Fade bottom delay={300}>
          <div className="shape shape-circle pattern-dots" />
        </Fade> */}

        <Fade bottom>
          <div className="shape shape-circle shape-circle-fill" />
        </Fade>

        <div className="pattern pattern-dots" />
      </div>

      <Container className="bring-to-front">
        <Row className="align-items-center">
          <Col md="6" className="order-md-last">
            <figure className="mockup" style={{ margin: "0 0 0 23px" }}>
              <ProposalSVG />
            </figure>
          </Col>

          <Col md="6">
            <h2 className="heading-line">
              A website is more than a page, its a solution to a problem.
            </h2>
            <p className="lead text-muted">
              See how we solve everyday problems while creating stunning
              websites, designs, and applications for our clients. At Stack Bit
              Labs we pride ourselves on delivering the best product a client
              could ask for.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Proposal
