import React from "react"
import { Container, Row, Col } from "reactstrap"
import PowerfulCard from "./powerfulCard.js"
import scrollTo from 'gatsby-plugin-smoothscroll'

const createCards = use => {
  let features = []
  let cards = [
    {
      class: "mt-6 mt-6 mx-lg-auto",
      icon: "paint",
      title: "UI Design",
      animation: { delay: 0 },
      description: "Slicing and dicing pixel perfect designs just for you",
    },

    {
      class: "mx-lg-auto",
      icon: "config",
      title: "Development",
      animation: { delay: 800 },
      description: "We use the newest technology to build optimal sites",
    },

    {
      class: "mr-lg-auto",
      icon: "box2",
      title: "Management",
      animation: { delay: 500 },
      description: "Need to manage your content? Let use get you started",
    },

    {
      class: "mt-6n mr-lg-auto",
      icon: "cloud",
      title: "Hosting",
      animation: { delay: 1200 },
      description:
        "Need a place for your site to live, consider our home yours",
    },
  ]

  cards.forEach((el, i) => {
    if (i % 2 === use) {
      features.push(<PowerfulCard {...el} key={i} />)
    }
  })

  return features
}

const PowerfulFeatures = () => {
  return (
    <section className="section alter3-features powerful-features">
      <div className="shapes-container">
        <div className="shape shape-ring shape-ring-1">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-ring shape-ring-2">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-circle animation--clockwise">
          <div />
        </div>

        <div className="shape background-shape-main" />
      </div>

      <Container>
        <Row>
          <Col lg="5" className="order-lg-last">
            <div className="section-heading">
              <p className="px-2 text-alternate text-uppercase bold">
                We cover every layer of the stack
              </p>
              <h2 className="heading-line">
                A complete offering built just for you
              </h2>

              <p className="lead text-muted my-4 semi-bold">
                Concept design, development, hosting or maintenance; whatever
                you need we have you covered.
              </p>

              <p>
                A web development company should be an expert in every aspect of
                the business. That is why we ensure your site will be safe in
                our hands from the first step to the last. But we don't stop
                there. At Stack Bit Labs we deliver more than a product, we
                deliver results that actually help your business. We understand
                that what a client really wants are results, not just another
                website. Our promise is to offer you a partnership that will
                bring lasting results.
              </p>

              <div
                tabIndex={0}
                role="button"
                className="btn btn-primary text-contrast bold"
                onClick={() => scrollTo('#contact-form')}
                onKeyDown={() => scrollTo('#contact-form')}
              >Contact Us Today</div>
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
            <Row>
              <Col lg="6" className="rotated-cards">
                {createCards(0)}
              </Col>
              <Col lg="6" className="rotated-cards">
                {createCards(1)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PowerfulFeatures
